const Configuration = {
  baseURL: 'http://localhost:8080/',
  provisionURL: 'http://192.168.4.1',
  mdashURL: 'https://mdash.net',
  addDeviceBaseUrl: 'http://dash.kegtron.com',
  appID: 'UCzqkgYtz91FnYAIo0uulaA', // <-- Set this to YOUR_APP_ID
  callTimeoutMilli: 10000, // 10 seconds
  defaultSiteName: 'NewSite',
  drinkSizes: {
    1000: 'Liter (1000 mL)',
    500: 'Half Liter (500 mL)',
    651: 'Bomber (651 mL)',
    568: 'UK Pint (568 mL)',
    473: 'US Pint (473 mL)',
    355: 'US Bottle (355 mL)',
    0: 'Custom',
  },
  drinkSizes_US: {
    1000: 'Liter (33.8oz)',
    500: 'Half Liter (16.9 oz)',
    651: 'Bomber (22.0 oz)',
    568: 'UK Pint (19.2 oz)',
    473: 'US Pint (16 oz)',
    355: 'US Bottle (12 oz)',
    0: 'Custom',
  },
  kegSizes: {
    9464: 'Half Corny (9.5L)',
    18927: 'Corny (18.9L)',
    // 19550: '1/6 Barrel (19.550L)',
    19558: '1/6 Barrel (19.558L)',
    20000: '20L',
    25000: '25L',
    // 29340: '1/4 Barrel (29.340L)',
    29337: '1/4 Barrel (29.337L)',
    30000: '30L',
    40915: 'Firkin (40.9L)',
    50000: '50L',
    // 58670: '1/2 Barrel (58.670L)',
    58674: '1/2 Barrel (58.674L)',
    0: 'Custom',
  },
  OldKegSizeObject: {
    58670: '1/2 Barrel (58.6L)',
    29340: '1/4 Barrel (29.340L)',
    19550: '1/6 Barrel (19.550L)',
  },
  OldKegSizesArray: [
    { title: '1/2 Barrel (58.6L)', value: 58670 },
    { title: '1/4 Barrel (29.340L)', value: 29340 },
    { title: '1/6 Barrel (19.550L)', value: 19550 },
    // 58670: '',
    // 29340: '1/4 Barrel (29.340L)',
    // 19550: '1/6 Barrel (19.550L)',
  ],
  kegSizes_US: {
    9464: 'Half Corny (2.500 gal)',
    18927: 'Corny (5.000 gal)',

    19558: '1/6 Barrel (5.166 gal)',

    20000: '20L (5.283 gal)',
    25000: '25L (6.604 gal)',

    29337: '1/4 Barrel (7.75 gal)',

    30000: '30L (7.925 gal)',
    40915: 'Firkin (10.808 gal)',
    50000: '50L (13.208 gal)',

    58674: '1/2 Barrel (15.500 gal)',

    0: 'Custom',
  },
};

export default Configuration;
