import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import Configuration from '../../configuration';
import { withTranslation, Trans } from "react-i18next";
import { AppConstant } from '../../constatnt/AppConstant';

 class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let self = this;
    console.log("self",self.props.lang);
    const {t}=self.props
  
		// i18n.changeLanguage(navigator.language)
  //   let {i18n}=this.props
	// let change=	i18n.changeLanguage(navigator.language)
  // // console.log("navigator",navigator);
    let accessToken = this.props.app.state.ktok;
    let addDeviceUrl = Configuration.addDeviceBaseUrl + '/#/add?access_token=' + accessToken +'&lang=' + this.props.lang;

    return (
      <footer class={style.footer}>
        <nav class='d-flex align-items-stretch border-top'>
          <Link
            activeClassName={style.active}
            href='/sites'
            onClick={function () {
              self.props.app.setState({ headerName: t(AppConstant.SITES) });
            }}
          >
            <span class='fa-fw fa fa-home' style='width: 2em' />
            <div class='small'>{t(AppConstant.SITES)}</div>
          </Link>
          {/* <Link activeClassName={style.active} href="/add-device" onClick={function () { self.props.app.setState({ headerName: "Add Device" }) }}>
                        <span class="fa-fw fa fa-plus-circle" style="width: 2em" />
                        <div class="small">Add Device</div>
                    </Link> */}

          <Link activeClassName={style.active} href={this.props.app.state.isDemo ? "" : addDeviceUrl} onClick={function () {}}>
            <span class='fa-fw fa fa-plus-circle' style='width: 2em' />
            <div class='small'>{t(AppConstant.ADD_DEVICE)}</div>
          </Link>

          <Link
            activeClassName={style.active}
            href='/reports'
            onClick={function () {
              self.props.app.setState({ headerName: t(AppConstant.REPORTS) });
            }}
          >
            <span class='fa-fw fa fa-file-text' style='width: 2em' />
            <div class='small'>{t(AppConstant.REPORTS)}</div>
          </Link>
          <Link
            activeClassName={style.active}
            href='/account'
            onClick={function () {
              self.props.app.setState({ headerName: t(AppConstant.ACCOUNT) });
            }}
          >
            <span class='fa-fw fa fa-user' style='width: 2em' />
            <div class='small'>{t(AppConstant.ACCOUNT)}</div>
          </Link>
        </nav>
      </footer>
    );
  }
}
export default withTranslation()(Footer);