const AppConstant={
EMAIL_PASSWORD_VALIDATION :"EMAIL_PASSWORD_VALIDATION",
SIGN_IN:"SIGN_IN", 
EMAIL: "EMAIL",
PASSWORD: "PASSWORD",
NO_ACCOUNT: "NO_ACCOUNT",
SEND_INVITATION:"SEND_INVITATION",
FORGOT_PASSWORD:"FORGOT_PASSWORD",
RESET_PASSWORD:"RESET_PASSWORD",
USE_KEGTRON_PRODUCTS:"USE_KEGTRON_PRODUCTS",
TERMS_AND_SERVICES : "TERMS_AND_SERVICES",
REGISTER:"REGISTER",
THANKYOU_FOR_CHECK_INBOX:"THANKYOU_FOR_CHECK_INBOX",
SITE: "SITE",
STATUS:"STATUS",
KEGS:"KEGS",
LOW:"Low",
EMPTY:"EMPTY",
NO_SITES:"NO_SITES",
SITES:"SITES",
REPORTS:"REPORTS",
SERVINGS_BY_KEG:"SERVINGS_BY_KEG",
SERVINGs_BY_TIME:"SERVINGs_BY_TIME",
INVENTORY:"INVENTORY",
CURRENT:"CURRENT",
PAST:"PAST",
TODAY:"TODAY",
YESTERDAY:"YESTERDAY",
WEEK_TO_DATE:"WEEK_TO_DATE",
LAST_WEEK:"LAST_WEEK",
MONTH_TO_DATE:"MONTH_TO_DATE",
LAST_MONTH:"LAST_MONTH",
YEAR_TO_DATE:"YEAR_TO_DATE",
CUSTOM:"CUSTOM",
RANGE:"RANGE",
START:"START",
END:"END",
DATE:"DATE",
START_OF_BUSINESS_DAY:"START_OF_BUSINESS_DAY",
TIME:"TIME",
PRINT:"PRINT",
EXPORT_AS_CSV:"EXPORT_AS_CSV",
TAP_HANDLE_NUMBER:"TAP_HANDLE_NUMBER",
NAME:"NAME",
SERVING_SIZE:"SERVING_SIZE",
TOTAL_SERVINGS:"TOTAL_SERVINGS",
TOTAL_VOLUME:"TOTAL_VOLUME",
KEG_NAME:"KEG_NAME",
SERVINGS:"SERVINGS",
VOLUME_SERVED:"VOLUME_SERVED",
LITRES_SERVED:"LITRES_SERVED",
GALLONS_SERVED:"GALLONS_SERVED",
OUNCES_SERVED:"OUNCES_SERVED",
SITE_NAME:"SITE_NAME",
GRAND_TOTAL_BY_SITE:"GRAND_TOTAL_BY_SITE",
NO_DATA_AVAILABLE_FOR_SELECTED_RANGE:"NO_DATA_AVAILABLE_FOR_SELECTED_RANGE",
TAP:"TAP",
TOTAL:"TOTAL",
GRAND_TOTAL_BY_KEG:"GRAND_TOTAL_BY_KEG",
GRAND_TOTAL:"GRAND_TOTAL",
SERVING_REPORT:"SERVING_REPORT",
INVENTORY_REPORT:"INVENTORY_REPORT",
DISTRIBUTOR:"DISTRIBUTOR",
COST:"COST",
LEVEL:"LEVEL",
VALUE:"VALUE",
TOTAL_KEGS:"TOTAL_KEGS",
TOTAL_VALUE:"TOTAL_VALUE",
KEG_COUNT:"KEG_COUNT",
KEGRON_SERVING_REPORT_FILE:"KEGRON_SERVING_REPORT_FILE",
KEGRON_INVENTORY_REPORT_FILE:"KEGRON_INVENTORY_REPORT_FILE",
KEGTRON_SERVING_REPORT:"KEGTRON_SERVING_REPORT",
KEGTRON_INVENTORY_REPORT:"KEGTRON_INVENTORY_REPORT",
ADD_DEVICE:"ADD_DEVICE",
ACCOUNT:"ACCOUNT",
LOGOUT:"LOGOUT",
GO_TO_YOUR_SETTINGS:"GO_TO_YOUR_SETTINGS",
JOIN_WIFI_NETWORK:"JOIN_WIFI_NETWORK",
RETURN_TO_THIS_SCREEN:"RETURN_TO_THIS_SCREEN",
SCAN:"SCAN",
BACK:"BACK",
FOUND_NEW_DEVICE:"FOUND_NEW_DEVICE",
WIFI_NETWORK_NAME:"WIFI_NETWORK_NAME",
WIFI_PASSWORD:"WIFI_PASSWORD",
WIFI_SETTING_APPLIED:"WIFI_SETTING_APPLIED",
JOIN_BACK_TO_YOUR_WIFI_NETWORK:"JOIN_BACK_TO_YOUR_WIFI_NETWORK",
RETURN_TO_THIS_SCREEN_AND_CLICK_ON_REGISTER_DEVICE:"RETURN_TO_THIS_SCREEN_AND_CLICK_ON_REGISTER_DEVICE",
REGISTER_DEVICE:"REGISTER_DEVICE",
ERROR_REGISTERING_DEVICE:"ERROR_REGISTERING_DEVICE",
JOIN_YOUR_WIFI_NETWORK_AND_RETRY:"JOIN_YOUR_WIFI_NETWORK_AND_RETRY",
CHANGE_PASSWORD:"CHANGE_PASSWORD",
TYPE_NEW_PASSWORD:"TYPE_NEW_PASSWORD",
RETYPE_PASSWORD:"RETYPE_PASSWORD",
API_KEY:"API_KEY",
COPIED:"COPIED",
CLICK_TO_COPY:"CLICK_TO_COPY",
SURE_WANT_TO_UPDATE_YOUR_PASSWORD:"SURE_WANT_TO_UPDATE_YOUR_PASSWORD",
PASSWORD_FIELDS_CANNOT_BE_BLANK:"PASSWORD_FIELDS_CANNOT_BE_BLANK",
PASSWORD_DONT_MATCH:"PASSWORD_DONT_MATCH",
TIMESTAMP:"TIMESTAMP",
MESSAGE:"MESSAGE",
DESCRIPTION:"DESCRIPTION",
PRODUCER:"PRODUCER",
STYLE:"STYLE",
ABV:"ABV",
IBU:"IBU",
SRM:"SRM",
TEMP:"TEMP",
HUMIDITY:"HUMIDITY",
MODAL:"MODAL",
PORT:"PORT",
WIFI_SSID:"WIFI_SSID",
RSSI:"RSSI",
STARTING_VOLUME:"STARTING_VOLUME",
REMAINING_VOLUME:"REMAINING_VOLUME",
LAST_CLEANED:"LAST_CLEANED",
DAYS_ON_TAP:"DAYS_ON_TAP",
KEGS_SERVED:"KEGS_SERVED",
LAST_SERVING:"LAST_SERVING",
RESET_KEG_VOLUME:"RESET_KEG_VOLUME",
GRAPH:"GRAPH",
NOW:"NOW",
SERVING_HISTORY:"SERVING_HISTORY",
VOLUME_HISTORY:"VOLUME_HISTORY",
YOUR_DEVICE_REQUIRE_A_FIRMWARE_UPDATE:"YOUR_DEVICE_REQUIRE_A_FIRMWARE_UPDATE",
ONLINE:"ONLINE",
OFFLINE:"OFFLINE",
PORTS:"PORTS",
HARDWARE_REV:"HARDWARE_REV",
FIRMWARE_REV:"FIRMWARE_REV",
SR_NO:"SR_NO",
HUMIDITY_OFFSET:"HUMIDITY_OFFSET",
TEMPERATURE_OFFSET:"TEMPERATURE_OFFSET",
FOR_BEST_AUTO_RESET_PERFORMANCE_ALERT_MSG:"FOR_BEST_AUTO_RESET_PERFORMANCE_ALERT_MSG",
TIME_BETWEEN_SERVINGS_SEC:"TIME_BETWEEN_SERVINGS_SEC",
ALERT_EMAIL:"ALERT_EMAIL",
ALERT_TEXT_SMS:"ALERT_TEXT_SMS",
LOW_THRESSHOLD:"LOW_THRESSHOLD",
EMPTY_THRESHOLD:"EMPTY_THRESHOLD",
HIGH_TEMPERATURE_THRESHOLD:"HIGH_TEMPERATURE_THRESHOLD",
LOW_TEMPERATURE_THRESHOLD:"LOW_TEMPERATURE_THRESHOLD",
HIGH_PRESSURE_A_THRESHOLD:"HIGH_PRESSURE_A_THRESHOLD",
LOW_PRESSURE_A_THRESHOLD:"LOW_PRESSURE_A_THRESHOLD",
HIGH_PRESSURE_B_THRESHOLD:"HIGH_PRESSURE_B_THRESHOLD",
LOW_PRESSURE_B_THRESHOLD:"LOW_PRESSURE_B_THRESHOLD",
PRESSURE_A_TYPE:"PRESSURE_A_TYPE",
PRESSURE_B_TYPE:"PRESSURE_B_TYPE",
PRESSURE_TYPE_LOWPRESS:"PRESSURE_TYPE_LOWPRESS",
PRESSURE_TYPE_HIGHPRESS:"PRESSURE_TYPE_HIGHPRESS",
KEG_AGE_ALERT_THRESHOLD:"KEG_AGE_ALERT_THRESHOLD",
CLEANING_OVERDUE_ALERT_THRESHOLD:"CLEANING_OVERDUE_ALERT_THRESHOLD",
DURATION:"DURATION",
SUNDAY:"SUNDAY",
MONDAY:"MONDAY",
TUESDAY:"TUESDAY",
WEDNESDAY:"WEDNESDAY",
THURSDAY:"THURSDAY",
FRIDAY:"FRIDAY",
SATURDAY:"SATURDAY",
TIME_ZONE:"TIME_ZONE",
SAVE_CONFIGURATION:"SAVE_CONFIGURATION",
SCROLL_TO_TOP:"SCROLL_TO_TOP",
OK:"OK",
CANCEL:"CANCEL",
SITE_NAME_EDIT_ALERT_MSG:"SITE_NAME_EDIT_ALERT_MSG",
KEG_VOLUME_START:"KEG_VOLUME_START",
KEG_COST:"KEG_COST",
CAL_OFFSET:"CAL_OFFSET",
DATE_TAPPED:"DATE_TAPPED",
DATE_CLEANED:"DATE_CLEANED",
SURE_TO_RESET_KEGS_SERVED_COUNT:"SURE_TO_RESET_KEGS_SERVED_COUNT",
PLEASE_ENTER_A_VALID_TIME_DURATION_FOR_BUSINESS_HOURS:"PLEASE_ENTER_A_VALID_TIME_DURATION_FOR_BUSINESS_HOURS",
PLEASE_ENTER_A_VALID_TIME_START_FOR_BUSINESS_HOURS:"PLEASE_ENTER_A_VALID_TIME_START_FOR_BUSINESS_HOURS",
TIME_BETWEEN_SERVINGS_MUST_BE_GREATER_THAN_0:"TIME_BETWEEN_SERVINGS_MUST_BE_GREATER_THAN_0",
CLEAN_DETECTION_TIME_MUST_BE_GREATER_THAN_0:"CLEAN_DETECTION_TIME_MUST_BE_GREATER_THAN_0",
IS_NOT_ALLOWED_IN_SITE_NAME:"IS_NOT_ALLOWED_IN_SITE_NAME",
SERV_SIZE_MUST_BE_GREATER_THAN_0:"SERV_SIZE_MUST_BE_GREATER_THAN_0",
KEG_SIZE_MUST_BE_GREATER_THAN_0:"KEG_SIZE_MUST_BE_GREATER_THAN_0",
SECONDS:"SECONDS",
WIRELESS:"WIRELESS",
BUSINESS_HOURS:"BUSINESS_HOURS",
SURE_TO_RESET_THIS_VOLUME:"SURE_TO_RESET_THIS_VOLUME",
VOL_REMAIN:"VOL_REMAIN",
SERVING_REMAIN:"SERVING_REMAIN",
SERV_LEFT:"SERV_LEFT",
SIZE:"SIZE",
SETTINGS:"SETTINGS",
VOLUME_RESET:"VOLUME_RESET",
CLEANING_CYCLE_MODE:"CLEANING_CYCLE_MODE",
CLEANING_CYCLE:"CLEANING_CYCLE",
KEG_SIZE:"KEG_SIZE",
DISPLAY_UNITS:"DISPLAY_UNITS",
US_CEREMONEY:"US_CEREMONEY",
METRIC:"METRIC",
DEVICE_BEACON:"DEVICE_BEACON",
DISABLE:"DISABLE",
ENABLE:"ENABLE",
AUTO_KEG_RESET:"AUTO_KEG_RESET",
CLEANING:"CLEANING",
CLEANING_CYCLE_COMMON_TO_ALL_PORTS:"CLEANING_CYCLE_COMMON_TO_ALL_PORTS",
CLEANING_CYCLE_INDEPENDENT_FOR_EACH_PORT:"CLEANING_CYCLE_INDEPENDENT_FOR_EACH_PORT",
CLEANING_AUTO_DISABLE_MIN:"CLEANING_AUTO_DISABLE_MIN",
MINUTES:"MINUTES",
CLEANING_AUTO_DETECT_SEC:"CLEANING_AUTO_DETECT_SEC",
SECONGS:"SECONGS",
ALERTS:"ALERTS",
LOW_ALARM:"LOW_ALARM",
NONE:"NONE",
EMAIL_ONLY:"EMAIL_ONLY",
TEXT_ONLY:"TEXT_ONLY",
TEXT:"TEXT",
EMPTY_ALARM:"EMPTY_ALARM",
AFTER_HOURS_ALERTS:"AFTER_HOURS_ALERTS",
TEMPRETURE_ALERTS:"TEMPRETURE_ALERTS",
PRESSURE_ALERTS:"PRESSURE_ALERTS",
DAYS:"DAYS",
OFFLINE_ALERTS:"OFFLINE_ALERTS",
SECONDARY_WIFI_SSID:"SECONDARY_WIFI_SSID",
SECONDARY_WIFI_PASSWORD:"SECONDARY_WIFI_PASSWORD",
BLUETOOTH_ACCESS:"BLUETOOTH_ACCESS",
STOP:"STOP",
ENABLING_CLEANING_MODE_WILL_DISABLE_ALL_MEASUREMENTS:"ENABLING_CLEANING_MODE_WILL_DISABLE_ALL_MEASUREMENTS",
MINUTES_IF_NOT_MANUALLY_DISABLED:"MINUTES_IF_NOT_MANUALLY_DISABLED",
RESET_KEGS_SERVED_COUNT:"RESET_KEGS_SERVED_COUNT",
STATE:"STATE",
UNUSED:"UNUSED",
IN_USE:"IN_USE",
BEACON:"BEACON",
EVENT_HISTORY:"EVENT_HISTORY",
KEG_COUNT_RESET:"KEG_COUNT_RESET",
PRESSURE_A:"PRESSURE_A",
PRESSURE_B:"PRESSURE_B",
MANUAL_UPDATE:"MANUAL_UPDATE",
INVITATION_MSG:"INVITATION_MSG",
RESET_PASS_MSG:"RESET_PASS_MSG",
MANUAL_SERV_DIALOG_HEADER: "MANUAL_SERV_DIALOG_HEADER",
OUNCES: "OUNCES",
MILLILITERS: "MILLILITERS",
MANUAL_SERV_DIALOG_LINE1: "MANUAL_SERV_DIALOG_LINE1",
MANUAL_SERV_DIALOG_LINE2: "MANUAL_SERV_DIALOG_LINE2",
UDP_STREAMING: "UDP_STREAMING",
CHANGE_PRES_TYPE_WARN:"CHANGE_PRES_TYPE_WARN",
CHECK_PRES_THRESHOLDS_A: "CHECK_PRES_THRESHOLDS_A",
CHECK_PRES_THRESHOLDS_B: "CHECK_PRES_THRESHOLDS_B",
DEMO_ALERT_MSG: "DEMO_ALERT_MSG"
}
export {
    AppConstant
}